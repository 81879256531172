<template>
  <v-autocomplete
    dense
    v-model="localValue"
    :items="taskPresets"
    item-text="display"
    item-value="display"
    hide-no-data
    hide-details
    :placeholder="$t('labels.tasks')"
    :menu-props="{ closeOnContentClick: true }"
    :loading="loading"
  />
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { CustomFieldMixin } from '@argon/app/mixins'
import { NAMESPACE } from '../../store'

export default {
  name: 'TaskPresetField',
  mixins: [CustomFieldMixin],
  data: () => ({
    loading: true
  }),
  methods: {
    ...mapActions(NAMESPACE, ['listTaskPresets'])
  },
  computed: {
    ...mapState(NAMESPACE, ['taskPresets'])
  },
  created() {
    this.listTaskPresets().then(() => {
      this.loading = false
    })
  }
}
</script>
