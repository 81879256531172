<template>
  <v-menu v-model="menu" min-width="250" bottom left :close-on-content-click="false">
    <template #activator="{ on: menu }">
      <v-tooltip top transition="fade-transition">
        <template #activator="{ on: tooltip }">
          <v-btn small icon slot="activator">
            <v-icon v-on="{ ...tooltip, ...menu }">{{ $vuetify.icons.values.add }}</v-icon>
          </v-btn>
        </template>
        <span>{{ $t('addClient') }}</span>
      </v-tooltip>
    </template>
    <v-card>
      <v-card-text>
        <ClientField v-model="clientId" />
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-btn color="accent" depressed @click="onSubmit" :disabled="!clientId">{{ $t('labels.add') }}</v-btn>
        <v-btn color="default" depressed @click="onNew">{{ $t('labels.new') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
import { mapActions } from 'vuex'
import { ClientField } from '../../../clients/components'
import { NAMESPACE } from '../../store'
export default {
  name: 'AddClient',
  components: {
    ClientField
  },
  props: {
    dealId: { type: [Number, String], required: true }
  },
  data: () => ({
    menu: null,
    clientId: null
  }),
  methods: {
    ...mapActions(NAMESPACE, ['createDealClient']),
    onClient(v) {
      this.clientId = v
    },
    onSubmit() {
      this.createDealClient({ dealId: this.dealId, clientId: this.clientId }).then(() => {
        this.menu = false
      })
    },
    onNew() {
      this.$router.push({
        name: 'nestedClient',
        params: { ...this.$route.params, clientId: 'new' }
      })
    }
  }
}
</script>

<i18n>
{
  "en": {
    "addClient": "Add Client"
  },
  "fr": {
    "addClient": "Ajouter un client"
  }
}
</i18n>
