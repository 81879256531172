<template>
  <VWidget content-class="pa-0">
    <template v-slot:title>{{ $t('labels.compliance') }}</template>
    <template v-slot:content>
      <v-list class="pa-0 ma-0">
        <v-list-item>
          <v-list-item-content>
            <v-list-item-subtitle>{{ $t(`labels.status`) }}</v-list-item-subtitle>
            <v-list-item-title>{{ $t(`labels.${complianceState}`) }}</v-list-item-title>
          </v-list-item-content>
          <v-list-item-action>
            <compliance-icon :deal="deal" small />
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </template>
  </VWidget>
</template>

<script>
import { VWidget } from '@argon/app/components'
import { ComplianceIcon } from '../common'

export default {
  name: 'VCompliance',
  components: { VWidget, ComplianceIcon },
  props: {
    deal: { type: Object, required: true }
  },
  computed: {
    complianceState() {
      return this.deal.compliance
    }
  }
}
</script>

<style></style>
