<template>
  <v-row>
    <v-col cols="12">
      <div class="text-h5">{{ dealClient.client.display }}</div>
    </v-col>
    <v-col cols="12" md="6">
      <v-row>
        <v-col cols="12">
          <ClientWidget
            :client-id="dealClient.client_id"
            edit-route="nestedClient"
            :read-only="readOnly"
            :key="`client-${dealClient.stamp}`"
          />
        </v-col>
        <v-col cols="12" v-if="dealClient.applicant">
          <ApplicantInfo :applicant="dealClient" :read-only="readOnly" />
        </v-col>
        <v-col cols="12" v-if="hasLiabilities">
          <Liabilities
            :liabilities="dealClient.applicant.liabilities"
            :client-id="dealClient.client_id"
            :key="`liabilities-${dealClient.stamp}`"
          />
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" md="6">
      <v-row>
        <v-col cols="12">
          <VMainClient :deal-client="dealClient" :read-only="readOnly" />
        </v-col>
        <v-col cols="12" v-if="canDelete">
          <VDeleteClient :deal-id="dealClient.deal_id" :client-id="dealClient.client_id" />
        </v-col>
        <v-col cols="12" v-if="hasAssets">
          <Assets
            :assets="dealClient.applicant.assets"
            :client-id="dealClient.client_id"
            :key="`assets-${dealClient.stamp}`"
          />
        </v-col>
        <v-col cols="12" v-if="hasEmployments">
          <Employment
            :jobs="dealClient.applicant.employments"
            :client-id="dealClient.client_id"
            :key="`employment-${dealClient.stamp}`"
          />
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import ApplicantInfo from './ApplicantInfo'
import Liabilities from './Liabilities'
import Assets from './Assets'
import Employment from './Employment'
import VMainClient from './VMainClient'
import { ClientWidget } from '../../../clients/components'
import VDeleteClient from './VDeleteClient'
import dealPerimeter from '../../perimeters'

export default {
  name: 'IndividualApplicant',
  perimeters: [dealPerimeter],
  components: {
    ApplicantInfo,
    Liabilities,
    Assets,
    Employment,
    ClientWidget,
    VMainClient,
    VDeleteClient
  },
  props: {
    dealClient: { type: Object, required: true },
    readOnly: { type: Boolean, default: false }
  },
  data: () => ({
    active: null
  }),
  computed: {
    hasLiabilities() {
      return this.dealClient.applicant && this.dealClient.applicant.liabilities.length > 0
    },
    hasAssets() {
      return this.dealClient.applicant && this.dealClient.applicant.assets.length > 0
    },
    hasEmployments() {
      return this.dealClient.applicant && this.dealClient.applicant.employments.length > 0
    },
    canDelete() {
      !this.readOnly && this.$deal.isAllowed('atLeastManager')
    }
  }
}
</script>
