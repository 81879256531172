<template>
  <VModalWidget :dismiss="dismiss" :submit="submit" no-esc :submitting="submitting">
    <template v-slot:title>{{ $t('labels.compliance') }}</template>

    <template v-slot:content>
      <FormErrors :show="showResourceErrors" :errors="resourceErrors" />
      <v-row>
        <v-col cols="12" v-if="isNew">
          <FileUploadField
            :label="$t('labels.selectFile')"
            :icon="$vuetify.icons.values.attachment"
            @input="onFiles"
            prefix="compliance_files"
            :errors="fileErrors"
            upload
          />
        </v-col>
        <v-col cols="12" v-else>
          <v-list dense>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>{{ form.file ? form.file.filename : '' }}</v-list-item-title>
                <v-list-item-subtitle>{{ transformDate(form.updated_on) }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-divider />
        </v-col>
        <v-col cols="12">
          <compliance-type-field v-model="form.compliance_type" :errors="complianceTypeErrors" />
        </v-col>
        <v-col cols="12">
          <v-select
            v-model="form.client_id"
            :items="boardItem.entity.clients"
            :label="$t('labels.client')"
            item-text="client.display"
            item-value="client.id"
            :error-messages="clientErrors"
          />
        </v-col>
      </v-row>
    </template>
  </VModalWidget>
</template>

<script>
import pick from 'lodash/pick'
import { required } from 'vuelidate/lib/validators'
import { mapState, mapActions, mapGetters } from 'vuex'
import { VModalWidget, FormErrors, BaseForm, FileUploadField } from '@argon/app/components'
import { NAMESPACE as ME_NAMESPACE } from '@argon/iam/me/store'
import { NAMESPACE as ENTITIES_NAMESPACE } from '@argon/evo/entities/store'
import { formatDT } from '@argon/app/helpers'
import { NAMESPACE as DEAL_NAMESPACE } from '../store'
import { ComplianceTypeField } from '../components'

export default {
  name: 'ComplianceModal',
  extends: BaseForm,
  components: {
    VModalWidget,
    FormErrors,
    ComplianceTypeField,
    FileUploadField
  },
  props: {
    fileId: { type: [String, Number], required: true }
  },
  validations: {
    form: {
      file_id: { required },
      compliance_type: { required },
      client_id: {}
    }
  },
  data: () => ({
    loading: true,
    submitting: false,
    form: {
      client_id: null,
      compliance_type: null,
      file_id: null
    },
    types: [
      { display: 'Cheque', value: 'check' },
      { display: 'Other', value: 'other' }
    ]
  }),
  computed: {
    ...mapGetters(ME_NAMESPACE, ['timezone', 'locale']),
    ...mapState(ENTITIES_NAMESPACE, ['boardItem']),
    isNew() {
      return this.fileId === 'new'
    },
    dealId() {
      return this.boardItem.entity.id
    },
    fileErrors() {
      const errors = []
      if (!this.$v.form.file_id.$dirty) return errors
      !this.$v.form.file_id.required && errors.push(this.$t('required'))
      return errors
    },
    complianceTypeErrors() {
      const errors = []
      if (!this.$v.form.compliance_type.$dirty) return errors
      !this.$v.form.compliance_type.required && errors.push(this.$t('required'))
      this.getServerErrors('compliance_type', errors)
      return errors
    },
    clientErrors() {
      const errors = []
      if (!this.$v.form.client_id.$dirty) return errors
      this.getServerErrors('client_id', errors)
      return errors
    }
  },
  methods: {
    ...mapActions(DEAL_NAMESPACE, ['getComplianceFile', 'createComplianceFile', 'patchComplianceFile']),
    transformDate(value) {
      return value ? formatDT(value, { format: 'LL', tz: this.timezone, locale: this.locale }) : ''
    },
    dismiss() {
      if (this.prevRoute && this.prevRoute.name) {
        this.$router.push(this.prevRoute)
      } else {
        this.$router.push({ name: 'boardCard', params: this.$route.params })
      }
    },

    onFiles(files) {
      this.form.file_id = files[0].id
    },

    uploadNew() {
      return this.createComplianceFile({ dealId: this.dealId, form: this.form })
    },
    updateExisting() {
      return this.patchComplianceFile({
        fileId: this.fileId,
        dealId: this.dealId,
        data: pick(this.form, ['compliance_type', 'client_id'])
      })
    },
    submit() {
      this.resetServerErrors()
      if (this.$v.form.$invalid) {
        this.$v.form.$touch()
        return
      }
      this.submitting = true
      const callback = this.isNew ? this.uploadNew : this.updateExisting
      callback()
        .then(() => {
          this.dismiss()
        })
        .catch((e) => {
          this.setServerErrors(e)
        })
        .finally(() => {
          this.submitting = false
        })
    }
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.prevRoute = from
    })
  },
  created() {
    if (this.isNew) {
      return (this.loading = true)
    }
    this.getComplianceFile({ dealId: this.dealId, fileId: this.fileId })
      .then((result) => {
        this.form = { ...result }
      })
      .finally(() => {
        this.loading = true
      })
  }
}
</script>
