<template>
  <VWidget content-class="pa-0">
    <template v-slot:title>{{ $t('labels.tasks') }}</template>
    <template v-slot:header-actions>
      <v-switch hide-details v-model="listParents" :label="$t('labels.listParents')" />
    </template>
    <template v-slot:content>
      <v-card class="elevation-0">
        <v-card-text>
          <FormErrors :show="showResourceErrors" :errors="resourceErrors" />

          <v-row v-if="canEdit">
            <v-col cols="12">
              <v-toolbar dense flat>
                <v-switch hide-details v-model="form.private" :label="$t('labels.private')" v-if="enablePrivate" />
                <v-spacer />
                <TaskPresetField v-model="preset" />
                <v-tooltip top transition="fade-transition">
                  <template v-slot:activator="{ on }">
                    <v-btn icon small class="py-0 px-0" @click="resetForm" v-on="on">
                      <v-icon small>{{ $vuetify.icons.values.reset }}</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('labels.reset') }}</span>
                </v-tooltip>
              </v-toolbar>
            </v-col>
            <v-col cols="5" v-if="useOwner">
              <UserField
                v-model="form.owner_id"
                :provided="members"
                :errors="ownerErrors"
                :label="$t('labels.owner')"
              />
            </v-col>
            <v-col :cols="useOwner ? 7 : 5">
              <DateTimeField v-model="date" :label="$t('labels.dueDate')" :errors="dateErrors" />
            </v-col>
            <v-col :cols="useOwner ? 12 : 7">
              <v-text-field
                dense
                v-model.trim="form.text"
                :label="localLabel"
                :error-messages="textErrors"
                required
                clearable
                v-on:keyup.enter="add"
                @click:append="add"
                :append-icon="$vuetify.icons.values.addCircleInverted"
              />
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-text class="pb-2 px-0 pt-0">
          <v-alert v-model="alert" dismissible type="error" transition="scale-transition" class="subheading mx-0">
            {{ $t(alertMessage) }}
          </v-alert>
          <v-data-table
            hide-default-header
            :headers="headers"
            :items="matches"
            :loading="loading"
            :server-items-length="matchesCount"
            :page.sync="page"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            must-sort
            :items-per-page.sync="itemsPerPage"
            :footer-props="{ itemsPerPageOptions: itemsPerPageArray }"
          >
            <template v-slot:[`item.check`]="{ item }">
              <v-checkbox height="12" @click="toggle(item)" :value="item.completed" v-if="!item.parent" />
            </template>
            <template v-slot:[`item.text`]="{ item }">
              <span :class="{ completed: item.completed, heading: true }">{{ item.text }}</span>
            </template>
            <template v-slot:[`item.owner`]="{ item }">
              {{ item.owner ? item.owner.display : null }}
            </template>
            <template v-slot:[`item.overdue`]="{ item }">
              <v-icon small v-if="item.overdue" :color="item.overdue ? 'error' : 'default'">
                {{ $vuetify.icons.values.reminder }}
              </v-icon>
            </template>
            <template v-slot:[`item.due_on`]="{ item }">
              {{ transformDate(item.due_on) }}
            </template>
            <template v-slot:[`item.private`]="{ item }">
              <v-chip label small color="warning" text-color="warning darken-4" v-if="item.private">
                {{ $t('labels.private') }}
              </v-chip>
              &nbsp;&nbsp;
              <v-chip label small color="default" text-color="warning darken-4" v-if="item.parent">
                {{ $t('labels.parent') }}
              </v-chip>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip top transition="fade-transition" v-if="canEditItem(item)">
                <template v-slot:activator="{ on }">
                  <v-btn icon small class="py-0 px-0" @click="loadData(item)" v-on="on">
                    <v-icon small>{{ $vuetify.icons.values.edit }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('labels.edit') }}</span>
              </v-tooltip>
              <v-tooltip top transition="fade-transition" v-if="canEditItem(item)">
                <template v-slot:activator="{ on }">
                  <v-btn icon small class="py-0 px-0" @click="remove(item)" v-on="on">
                    <v-icon small>{{ $vuetify.icons.values.cancel }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('labels.delete') }}</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </template>
  </VWidget>
</template>

<script>
import { required, requiredIf } from 'vuelidate/lib/validators'
import moment from 'moment-timezone'

import { formatDT } from '@argon/app/helpers'
import { FormErrors, DateTimeField, VWidget } from '@argon/app/components'
import { UserField } from '@argon/iam/components'
import TaskPresetField from './TaskPresetField'
import DealAddonMixin from './DealAddonMixin'

const ADDON = 'tasks'

export default {
  name: 'Tasks',
  mixins: [DealAddonMixin],
  components: {
    FormErrors,
    UserField,
    DateTimeField,
    TaskPresetField,
    VWidget
  },
  validations: {
    form: {
      text: { required },
      due_on: { required },
      owner_id: {
        required: requiredIf('useOwner')
      }
    }
  },
  data: () => ({
    listParents: false,
    menu: false,
    form: {
      text: '',
      due_on: '',
      owner_id: null,
      private: false
    },
    itemsPerPage: 10,
    sortBy: 'due_on',
    sortDesc: true,
    addonName: ADDON,
    localPreset: null,
    headers: [
      { text: '', value: 'check', width: 10 },
      { text: '', value: 'text' },
      { text: '', value: 'owner' },
      { text: '', value: 'overdue', width: 20 },
      { text: '', value: 'due_on' },
      { text: '', value: 'private', width: 30 },
      { text: '', value: 'actions', align: 'right', width: 30, align: 'right' }
    ]
  }),
  computed: {
    localLabel() {
      return this.label || this.$t('addTask')
    },
    preset: {
      get() {
        return this.localPreset
      },
      set(v) {
        this.localPreset = v
        this.form.text = v
      }
    },
    date: {
      get() {
        return this.form.due_on ? moment(this.form.due_on).tz(this.timezone).toISOString() : ''
      },
      set(value) {
        this.form.due_on = value ? moment.tz(value, this.timezone).clone().tz('UTC') : value
      }
    },
    formattedDate() {
      return this.form.due_on ? this.transformDate(this.form.due_on) : ''
    },
    ownerErrors() {
      const errors = []
      if (!this.$v.form.owner_id.$dirty) return errors
      !this.$v.form.owner_id.required && errors.push(this.$t('required'))
      this.getServerErrors('owner_id', errors)
      return errors
    },
    textErrors() {
      const errors = []
      if (!this.$v.form.text.$dirty) return errors
      !this.$v.form.text.required && errors.push(this.$t('required'))
      this.getServerErrors('text', errors)
      return errors
    },
    dateErrors() {
      const errors = []
      if (!this.$v.form.due_on.$dirty) return errors
      !this.$v.form.due_on.required && errors.push(this.$t('required'))
      this.getServerErrors('due_on', errors) // not camelCased in payload
      return errors
    }
  },
  methods: {
    resetForm() {
      this.editingId = null
      this.form = { text: '', owner_id: null, due_on: '', private: false }
      this.$v.$reset()
    },
    toggle(item) {
      this.update(item, {
        done_on: item.completed ? null : moment().tz(this.timezone).toISOString()
      })
    },
    transformDate(value) {
      return value ? formatDT(value, { format: 'LLL', tz: this.timezone, locale: this.locale }) : ''
    },
    buildData() {
      return this.useOwner
        ? { text: this.form.text, owner_id: this.form.owner_id, due_on: this.form.due_on, private: this.form.private }
        : { text: this.form.text, due_on: this.form.due_on, private: this.form.private }
    }
  }
}
</script>

<i18n>
{
  "en": {
    "addTask": "Add Task",
    "updateError": "Error updating Task",
    "deleteError": "Error deleting Task"
  },
  "fr": {
    "addTask": "Ajouter une tâche",
    "updateError": "Erreur de mise à jour de la tâche",
    "deleteError": "Erreur de suppression de la tâche"
  }
}
</i18n>
<style scoped>
span.completed {
  text-decoration: line-through;
}
</style>
