var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('VWidget',{attrs:{"content-class":"pa-0"},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(_vm._s(_vm.$t('labels.compliance')))]},proxy:true},(!_vm.readOnly)?{key:"header-actions",fn:function(){return [(_vm.canManage)?_c('v-switch',{staticClass:"shrink mx-2 my-0",attrs:{"hide-details":"","label":_vm.$t('labels.override')},model:{value:(_vm.complianceOverride),callback:function ($$v) {_vm.complianceOverride=$$v},expression:"complianceOverride"}}):_vm._e(),_c('compliance-icon',{attrs:{"deal":_vm.deal,"small":"","tooltip":""}}),_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":_vm.create}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(_vm._s(_vm.$vuetify.icons.values.add))])],1)]},proxy:true}:null,{key:"content",fn:function(){return [_c('v-data-table',{attrs:{"hide-default-header":"","headers":_vm.headers,"items":_vm.localFiles,"loading":_vm.loading,"server-items-length":_vm.complianceFilesCount,"page":_vm.page,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"must-sort":"","items-per-page":_vm.itemsPerPage,"footer-props":{ itemsPerPageOptions: _vm.itemsPerPageArray }},on:{"update:page":function($event){_vm.page=$event},"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event},"update:itemsPerPage":function($event){_vm.itemsPerPage=$event},"update:items-per-page":function($event){_vm.itemsPerPage=$event}},scopedSlots:_vm._u([{key:"item.valid",fn:function(ref){
var item = ref.item;
return [(item.valid)?_c('v-icon',{attrs:{"small":"","color":"success"}},[_vm._v(_vm._s(_vm.$vuetify.icons.values.check))]):_c('v-icon',{attrs:{"small":"","color":"warning"}},[_vm._v(_vm._s(_vm.$vuetify.icons.values.warning))])]}},{key:"item.required",fn:function(ref){
var item = ref.item;
return [(_vm.boardItem.entity.meta.required_compliance_types.includes(item.compliance_type))?_c('v-icon',{attrs:{"small":"","color":"default"}},[_vm._v(_vm._s(_vm.$vuetify.icons.values.password))]):_vm._e()]}},{key:"item.compliance_type",fn:function(ref){
var item = ref.item;
return [_c('span',{class:{ 'error--text': !!item.sample }},[_vm._v(_vm._s(item.display))]),_c('div',{staticClass:"text-caption grey--text"},[_vm._v(_vm._s(item.sample ? '' : item.client.display))])]}},{key:"item.filename",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.sample ? '' : item.file.filename)+" "),_c('div',{staticClass:"text-caption grey--text"},[_vm._v(_vm._s(_vm.transformDate(item.updated_on)))])]}},(!_vm.readOnly)?{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(item.sample)?_c('v-tooltip',{attrs:{"top":"","transition":"fade-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.create(item.compliance_type)}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(_vm._s(_vm.$vuetify.icons.values.add))])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('labels.create')))])]):_c('span',[(!item.valid && _vm.$deal.isAllowed('atLeastManager'))?_c('v-tooltip',{attrs:{"top":"","transition":"fade-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.validate(item)}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(_vm._s(_vm.$vuetify.icons.values.check))])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('labels.validate')))])]):_vm._e(),(_vm.$deal.isAllowed('atLeastAdvisor'))?_c('v-tooltip',{attrs:{"top":"","transition":"fade-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.edit(item)}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(_vm._s(_vm.$vuetify.icons.values.edit))])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('labels.edit')))])]):_vm._e(),_c('DownloadBtn',{attrs:{"file":item.file}}),_c('v-tooltip',{attrs:{"top":"","transition":"fade-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.destroy(item)}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(_vm._s(_vm.$vuetify.icons.values.trash))])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('labels.delete')))])])],1)]}}:null],null,true)})]},proxy:true}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }