<template>
  <VWidget content-class="pa-0">
    <template v-slot:title>{{ $t('title') }}</template>
    <template v-slot:content>
      <v-list class="pa-0 ma-0">
        <v-list-item>
          <v-list-item-content>
            <v-list-item-subtitle>{{ $t('applicantType') }}</v-list-item-subtitle>
            <v-list-item-title>{{ applicant.applicant.type }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-subtitle>{{ $t('maritalStatus') }}</v-list-item-subtitle>
            <v-list-item-title>{{ applicant.applicant.marital_status }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-subtitle>{{ $t('relationToPrimary') }}</v-list-item-subtitle>
            <v-list-item-title>{{ applicant.applicant.rel_to_main }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-subtitle>{{ $t('preferredContactMethod') }}</v-list-item-subtitle>
            <v-list-item-title>{{ applicant.applicant.contact_method }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-subtitle>{{ $t('numberOfDeps') }}</v-list-item-subtitle>
            <v-list-item-title>{{ applicant.applicant.number_of_dependents }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-subtitle>{{ $t('firstTimeBuyer') }}</v-list-item-subtitle>
            <v-list-item-title>{{ applicant.applicant.first_time_buyer }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </template>
  </VWidget>
</template>

<script>
import { VWidget } from '@argon/app/components'

export default {
  components: { VWidget },
  name: 'ApplicantInfo',
  props: {
    applicant: { type: Object, required: true }
  }
}
</script>

<i18n>
{
  "en": {
    "applicantType": "@:labels.applicantType",
    "birthDate": "@:labels.birthDate",
    "firstTimeBuyer": "@:labels.firstTimeBuyer",
    "languagePref": "@:labels.languagePref",
    "maritalStatus": "@:labels.maritalStatus",
    "numberOfDeps": "@:labels.numberOfDeps",
    "preferredContactMethod": "@:labels.preferredContactMethod",
    "relationToPrimary": "@:labels.relationToPrimary",
    "title": "Applicant Info"
  },
  "fr": {
    "applicantType": "@:labels.applicantType",
    "birthDate": "@:labels.birthDate",
    "firstTimeBuyer": "@:labels.firstTimeBuyer",
    "languagePref": "@:labels.languagePref",
    "maritalStatus": "@:labels.maritalStatus",
    "numberOfDeps": "@:labels.numberOfDeps",
    "preferredContactMethod": "@:labels.preferredContactMethod",
    "relationToPrimary": "@:labels.relationToPrimary",
    "title": "Info du demandeur"
  }
}
</i18n>
