<template>
  <VWidget content-class="pa-0">
    <template v-slot:title>{{ $t('title') }}</template>
    <template v-slot:content>
      <v-list class="pa-0 ma-0">
        <v-list-item>
          <v-list-item-content>
            <v-list-item-subtitle>{{ $t('labels.status') }}</v-list-item-subtitle>
            <v-list-item-title>{{ $t(`labels.${deal.status}`) }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider />
        <v-list-item>
          <v-list-item-content>
            <v-list-item-subtitle>{{ $t('labels.step') }}</v-list-item-subtitle>
            <v-list-item-title v-if="localDeal && localDeal.stateObj">
              {{ $t(`labels.${localDeal.stateObj.display}`) }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider />
        <v-list-item>
          <v-list-item-content>
            <v-list-item-subtitle>{{ $t('labels.createdOn') }}</v-list-item-subtitle>
            <v-list-item-title>{{ transformDate(localDeal.created_on) }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </template>
  </VWidget>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { NAMESPACE as ME_NAMESPACE } from '@argon/iam/me/store'
import { NAMESPACE as BOARD_NAMESPACE } from '@argon/evo/boards/store'
import { formatDT } from '@argon/app/helpers'
import { VWidget } from '@argon/app/components'

export default {
  name: 'VDealProgress',
  components: { VWidget },
  props: {
    deal: { type: Object, required: true }
  },
  computed: {
    ...mapState(BOARD_NAMESPACE, ['columns', 'stateAttr']),
    ...mapGetters(ME_NAMESPACE, ['timezone', 'locale']),
    localDeal() {
      let stateObj = this.columns.find((state) => this.deal[this.stateAttr] === state.name)
      return { ...this.deal, stateObj }
    }
  },
  methods: {
    transformDate(value) {
      return value ? formatDT(value, { format: 'LL', tz: this.timezone, locale: this.locale }) : ''
    }
  }
}
</script>

<i18n>
{
  "en": { "title": "Progress"},
  "fr": { "title": "Progression"}
}
</i18n>
