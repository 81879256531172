<template>
  <VWidget content-class="pa-0">
    <template v-slot:title>{{ $t('title') }}</template>
    <template v-slot:content>
      <v-list class="pa-0 ma-0" subheader v-for="(liability, idx) in liabilities" :key="`liability-${idx}-${clientId}`">
        <v-divider v-if="idx !== 0" />
        <v-subheader class="subheading black--text">{{ liability.description }}</v-subheader>
        <v-divider />
        <v-list-item>
          <v-list-item-content>
            <v-list-item-subtitle>{{ $t('annualPayment') }}</v-list-item-subtitle>
            <v-list-item-title>{{ toCurrency(liability.annual) }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider />
        <v-list-item>
          <v-list-item-content>
            <v-list-item-subtitle>{{ $t('creditLimit') }}</v-list-item-subtitle>
            <v-list-item-title>{{ toCurrency(liability.credit_limit) }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider />
        <v-list-item>
          <v-list-item-content>
            <v-list-item-subtitle>{{ $t('amount') }}</v-list-item-subtitle>
            <v-list-item-title>{{ toCurrency(liability.amount) }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider />
        <v-list-item>
          <v-list-item-content>
            <v-list-item-subtitle>{{ $t('monthlyPayment') }}</v-list-item-subtitle>
            <v-list-item-title>{{ toCurrency(liability.monthly) }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </template>
  </VWidget>
</template>

<script>
import { mapGetters } from 'vuex'
import { NAMESPACE as ME_NAMESPACE } from '@argon/iam/me/store'
import { VWidget } from '@argon/app/components'
import { money } from '../../../utils'

export default {
  components: { VWidget },
  props: {
    clientId: { type: [String, Number], required: true },
    liabilities: { type: Array, default: () => [] }
  },
  computed: {
    ...mapGetters(ME_NAMESPACE, ['locale'])
  },
  methods: {
    toCurrency(v) {
      return money(v, this.locale)
    }
  }
}
</script>

<style scoped lang="scss">
.subheading {
  background-color: #fafafa;
  font-size: $font-size-root;
}
</style>

<i18n>
{
  "en": {
    "amount": "@:labels.amount",
    "annualPayment": "@:labels.annualPayment",
    "creditLimit": "@:labels.creditLimit",
    "monthlyPayment": "@:labels.monthlyPayment",
    "title": "@:labels.liabilities"
  },
  "fr": {
    "amount": "@:labels.amount",
    "annualPayment": "@:labels.annualPayment",
    "creditLimit": "@:labels.creditLimit",
    "monthlyPayment": "@:labels.monthlyPayment",
    "title": "@:labels.liabilities"
  }
}
</i18n>
