<template>
  <VWidget content-class="pa-0">
    <template v-slot:title>{{ $t('title') }}</template>
    <template v-slot:content>
      <v-list class="pa-0 ma-0">
        <v-list-item>
          <v-list-item-content>
            <v-list-item-subtitle>{{ $t('labels.refnum') }}</v-list-item-subtitle>
            <v-list-item-title>{{ deal.refnum }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-subtitle>{{ $t('labels.mortgageAgency') }}</v-list-item-subtitle>
            <v-list-item-title>{{ deal.firm ? deal.firm.display : '' }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider />
        <VBrokerListItem :broker-id="deal.broker_id" label="broker" />
      </v-list>
    </template>
  </VWidget>
</template>

<script>
import { VWidget } from '@argon/app/components'
import { VBrokerListItem } from '../../../brokers/components'

export default {
  name: 'VDealParticipants',
  components: { VWidget, VBrokerListItem },
  props: {
    deal: { type: Object, required: true }
  },
  methods: {
    editFirm() {
      this.$router.push({
        name: 'nestedFirm',
        params: {
          ...this.$route.params,
          firmCode: this.deal.firm_code || 'new'
        }
      })
    }
  }
}
</script>

<i18n>
{
  "en": { "title": "Participants"},
  "fr": { "title": "Participants"}
}
</i18n>
