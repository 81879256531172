<template>
  <VWidget content-class="pa-0">
    <template v-slot:title>{{ $t('labels.tasks') }}</template>
    <template v-slot:header-actions>
      <v-switch hide-details v-model="listParents" :label="$t('labels.listParents')" />
    </template>
    <template v-slot:content>
      <v-card flat class="elevation-0">
        <v-card-text>
          <FormErrors :show="showResourceErrors" :errors="resourceErrors" />
          <v-row v-if="canEdit">
            <v-col cols="12">
              <v-toolbar dense flat>
                <v-switch
                  hide-details
                  v-model="form.private"
                  class="shrink mr-2 mt-0"
                  :label="$t('labels.private')"
                  v-if="enablePrivate"
                />
                <v-spacer />
                <NotePresetField v-model="preset" v-if="enablePrivate" />

                <v-tooltip top transition="fade-transition">
                  <template v-slot:activator="{ on }">
                    <v-btn icon small class="py-0 px-0" @click="resetForm" v-on="on">
                      <v-icon small>{{ $vuetify.icons.values.reset }}</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('labels.reset') }}</span>
                </v-tooltip>
              </v-toolbar>
            </v-col>
            <v-col cols="12">
              <tiptap-vuetify
                v-model="form.text"
                :extensions="extensions"
                :placeholder="localLabel"
                :toolbar-attributes="{ color: 'grey lighten-3' }"
                :card-props="{ outlined: true }"
              >
                <template v-slot:toolbar-after>
                  <template v-for="(msg, idx) in textErrors">
                    <v-alert dense type="error" text dismissible :key="`note-error-${idx}`">{{ msg }}</v-alert>
                  </template>
                </template>
                <template v-slot:footer>
                  <v-divider />
                  <v-row class="pa-1">
                    <v-col>
                      <v-chip
                        v-for="(item, idx) in placeholders"
                        label
                        small
                        color="default"
                        :key="`${idx}-placeholder`"
                        class="mr-1"
                        @click="addItem(item)"
                      >
                        {{ $t(`labels.${item}`) }}
                      </v-chip>
                    </v-col>
                  </v-row>
                </template>
              </tiptap-vuetify>
            </v-col>
            <v-col cols="12" class="text-right">
              <v-btn depressed color="default" @click="add(form)">
                {{ editingId ? $t('labels.update') : $t('labels.add') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-text class="pb-2 px-0 pt-0">
          <v-data-table
            hide-default-header
            :items="matches"
            class="elevation-0"
            :loading="loading"
            :server-items-length="matchesCount"
            :page.sync="page"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            must-sort
            :items-per-page.sync="itemsPerPage"
            :footer-props="{ itemsPerPageOptions: itemsPerPageArray }"
          >
            <template v-slot:item="{ item }">
              <tr>
                <td class="comment">
                  <v-card flat color="transparent">
                    <v-toolbar dense flat color="transparent">
                      <v-avatar size="32px">
                        <img :src="item.owner.avatar" v-if="item.owner" />
                      </v-avatar>
                      &nbsp;&nbsp;
                      <v-toolbar-title v-if="item.owner">
                        {{ item.owner.display }}
                      </v-toolbar-title>

                      <v-spacer></v-spacer>
                      <span class="text-caption text--disabled">
                        {{ transformDate(item.created_on) }}
                      </span>
                      &nbsp;&nbsp;
                      <v-chip label small color="warning" text-color="warning darken-4" v-if="item.private">
                        {{ $t('labels.private') }}
                      </v-chip>
                      &nbsp;&nbsp;
                      <v-chip label small color="default" text-color="warning darken-4" v-if="item.parent">
                        {{ $t('labels.parent') }}
                      </v-chip>
                      &nbsp;&nbsp;
                      <v-tooltip top transition="fade-transition" v-if="canEditItem(item)">
                        <template v-slot:activator="{ on }">
                          <v-btn icon small class="py-0 px-0" @click="loadData(item)" v-on="on">
                            <v-icon small>{{ $vuetify.icons.values.edit }}</v-icon>
                          </v-btn>
                        </template>
                        <span>{{ $t('labels.edit') }}</span>
                      </v-tooltip>
                      <v-tooltip top transition="fade-transition" v-if="canEditItem(item)">
                        <template v-slot:activator="{ on }">
                          <v-btn icon small class="py-0 px-0" @click="remove(item)" v-on="on">
                            <v-icon small>{{ $vuetify.icons.values.cancel }}</v-icon>
                          </v-btn>
                        </template>
                        <span>{{ $t('labels.delete') }}</span>
                      </v-tooltip>
                    </v-toolbar>
                    <v-card-text v-html="item.text" />
                  </v-card>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </template>
  </VWidget>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import {
  TiptapVuetify,
  Heading,
  Bold,
  Italic,
  Blockquote,
  ListItem,
  BulletList,
  OrderedList,
  HorizontalRule
} from 'tiptap-vuetify'
import { formatDT } from '@argon/app/helpers'
import { FormErrors, VWidget } from '@argon/app/components'
import NotePresetField from './NotePresetField'
import dealPerimeter from '@/deals/perimeters'
import entityPerimeter from '@argon/evo/entities/perimeters'
import { hasValidPlaceholders } from '@/utils'
import DealAddonMixin from './DealAddonMixin'

const ADDON = 'comments'

export default {
  name: 'Notes',
  mixins: [DealAddonMixin],
  perimeters: [entityPerimeter, dealPerimeter],
  components: {
    FormErrors,
    TiptapVuetify,
    NotePresetField,
    VWidget
  },
  validations: {
    form: {
      text: { required, hasValidPlaceholders }
    }
  },
  data: () => ({
    itemsPerPage: 5,
    itemsPerPageArray: [5, 10, 20],
    form: {
      text: '',
      private: true
    },
    localPreset: null,
    sortBy: 'created_on',
    sortDesc: true,
    addonName: ADDON,
    placeholders: [
      'inquire_refnum',
      'inquire_name',
      'inquire_status',
      'inquire_step',
      'client',
      'clients',
      'referer',
      'agent'
    ],
    extensions: [
      Bold,
      Italic,
      [
        Heading,
        {
          options: {
            levels: [1, 2, 3]
          }
        }
      ],
      ListItem,
      Blockquote,
      BulletList,
      OrderedList,
      HorizontalRule
    ]
  }),
  methods: {
    transformDate(value) {
      return value ? formatDT(value, { format: 'LLL', tz: this.timezone, locale: this.locale }) : ''
    },
    resetForm() {
      this.editingId = null
      this.form = { text: '', private: true }
    },
    buildData() {
      return { text: this.form.text, private: this.form.private }
    },
    canEditItem(instance) {
      if (instance.parent) {
        return false
      }
      if (this.$deal.isAllowed('atLeastManager')) {
        return true
      }
      return instance.owner_id === this.userId
    },
    addItem(text) {
      this.form.text = this.form.text.concat(`{${text}}`)
    }
  },
  computed: {
    localLabel() {
      return this.label || this.$t('labels.addNote')
    },
    preset: {
      get() {
        return this.localPreset
      },
      set(v) {
        this.localPreset = v
        this.form.text = v
      }
    },
    textErrors() {
      const errors = []
      if (!this.$v.form.text.$dirty) return errors
      !this.$v.form.text.required && errors.push(this.$t('labels.required'))
      !this.$v.form.text.hasValidPlaceholders && errors.push(this.$t('labels.invalidBody'))
      this.getServerErrors('text', errors)
      return errors
    },
    textColor() {
      return this.textErrors ? 'error' : 'transparent'
    }
  }
}
</script>

<style scoped lang="scss">
::v-deep .v-input--selection-controls .v-input__slot {
  margin-bottom: 0;
}

.avatar {
  vertical-align: top;
  width: 32px;
}
.v-data-table td.comment {
  padding: 5px;
}
</style>
