<template>
  <VWidget content-class="pa-0">
    <template v-slot:title>{{ $t('labels.compliance') }}</template>
    <template v-slot:header-actions v-if="!readOnly">
      <v-switch
        hide-details
        v-model="complianceOverride"
        v-if="canManage"
        :label="$t('labels.override')"
        class="shrink mx-2 my-0"
      />
      <compliance-icon :deal="deal" small tooltip />
      <v-btn icon small @click="create">
        <v-icon small>{{ $vuetify.icons.values.add }}</v-icon>
      </v-btn>
    </template>
    <template v-slot:content>
      <v-data-table
        hide-default-header
        :headers="headers"
        :items="localFiles"
        :loading="loading"
        :server-items-length="complianceFilesCount"
        :page.sync="page"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        must-sort
        :items-per-page.sync="itemsPerPage"
        :footer-props="{ itemsPerPageOptions: itemsPerPageArray }"
      >
        <template v-slot:[`item.valid`]="{ item }">
          <v-icon small v-if="item.valid" color="success">{{ $vuetify.icons.values.check }}</v-icon>
          <v-icon small v-else color="warning">{{ $vuetify.icons.values.warning }}</v-icon>
        </template>
        <template v-slot:[`item.required`]="{ item }">
          <v-icon
            small
            v-if="boardItem.entity.meta.required_compliance_types.includes(item.compliance_type)"
            color="default"
            >{{ $vuetify.icons.values.password }}</v-icon
          >
        </template>
        <template v-slot:[`item.compliance_type`]="{ item }">
          <span :class="{ 'error--text': !!item.sample }">{{ item.display }}</span>
          <div class="text-caption grey--text">{{ item.sample ? '' : item.client.display }}</div>
        </template>
        <template v-slot:[`item.filename`]="{ item }">
          {{ item.sample ? '' : item.file.filename }}
          <div class="text-caption grey--text">{{ transformDate(item.updated_on) }}</div>
        </template>
        <template v-slot:[`item.actions`]="{ item }" v-if="!readOnly">
          <v-tooltip top transition="fade-transition" v-if="item.sample">
            <template v-slot:activator="{ on }">
              <v-btn icon small @click="create(item.compliance_type)" v-on="on">
                <v-icon small>{{ $vuetify.icons.values.add }}</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('labels.create') }}</span>
          </v-tooltip>
          <span v-else>
            <v-tooltip top transition="fade-transition" v-if="!item.valid && $deal.isAllowed('atLeastManager')">
              <template v-slot:activator="{ on }">
                <v-btn icon small @click="validate(item)" v-on="on">
                  <v-icon small>{{ $vuetify.icons.values.check }}</v-icon>
                </v-btn>
              </template>
              <span>{{ $t('labels.validate') }}</span>
            </v-tooltip>
            <v-tooltip top transition="fade-transition" v-if="$deal.isAllowed('atLeastAdvisor')">
              <template v-slot:activator="{ on }">
                <v-btn icon small @click="edit(item)" v-on="on">
                  <v-icon small>{{ $vuetify.icons.values.edit }}</v-icon>
                </v-btn>
              </template>
              <span>{{ $t('labels.edit') }}</span>
            </v-tooltip>
            <DownloadBtn :file="item.file" />
            <v-tooltip top transition="fade-transition">
              <template v-slot:activator="{ on }">
                <v-btn icon small @click="destroy(item)" v-on="on">
                  <v-icon small>{{ $vuetify.icons.values.trash }}</v-icon>
                </v-btn>
              </template>
              <span>{{ $t('labels.delete') }}</span>
            </v-tooltip>
          </span>
        </template>
      </v-data-table>
    </template>
  </VWidget>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex'
import { create } from 'vue-modal-dialogs'
import { NAMESPACE as ME_NAMESPACE } from '@argon/iam/me/store'
import { Datatable } from '@argon/app/mixins'
import { NAMESPACE as DEAL_NAMESPACE } from '../../store'
import { formatDT } from '@argon/app/helpers'
import { VWidget, DownloadBtn } from '@argon/app/components'
import entityPerimeter from '@argon/evo/entities/perimeters'
import dealPerimeter from '../../perimeters'
import ConfirmDeleteFileDialog from './ConfirmDeleteFileDialog'
import { ComplianceIcon } from '../common'

const confirmDeleteAction = create(ConfirmDeleteFileDialog)

export default {
  name: 'ComplianceFiles',
  mixins: [Datatable],
  perimeters: [entityPerimeter, dealPerimeter],
  components: { VWidget, ComplianceIcon, DownloadBtn },
  props: {
    boardItem: { type: Object, required: true },
    readOnly: { type: Boolean, default: false }
  },
  data: () => ({
    headers: [
      { text: '', value: 'valid', sortable: false },
      { text: '', value: 'required', sortable: false },
      { text: '', value: 'compliance_type', sortable: false },
      { text: '', value: 'filename', sortable: false },
      { text: '', sortable: false, value: 'actions', align: 'right' }
    ],
    loading: true
  }),
  computed: {
    ...mapGetters(ME_NAMESPACE, ['timezone', 'locale']),
    ...mapState(DEAL_NAMESPACE, ['complianceFiles', 'complianceFilesCount', 'requiredCompliance']),
    deal() {
      return this.boardItem.entity
    },
    canEdit() {
      return this.$entity.isAllowed('update', this.boardItem.entity)
    },
    canManage() {
      return this.$entity.isAllowed('manage', this.boardItem.entity)
    },
    localFiles() {
      let existing = this.complianceFiles.map((x) => x.compliance_type)
      let required = this.requiredCompliance
        .filter((x) => !existing.includes(x.compliance_type))
        .map((x) => ({ ...x, sample: true }))
      return [...required, ...this.complianceFiles]
    },
    complianceState() {
      return this.deal.compliance
    },
    complianceOverride: {
      get() {
        return this.deal.meta.compliance_override
      },
      set(v) {
        this.patchDeal({ dealId: this.deal.id, data: { meta: { compliance_override: v } } })
      }
    },
    iconColor() {
      switch (this.deal.compliance) {
        case 'valid':
        case 'overridden':
          return 'success'
        case 'pending':
          return 'warning'
        default:
          return 'error'
      }
    },
    icon() {
      switch (this.deal.compliance) {
        case 'valid':
        case 'overridden':
          return this.$vuetify.icons.values.check
        case 'pending':
          return this.$vuetify.icons.values.warning
        default:
          return this.$vuetify.icons.values.error
      }
    }
  },
  methods: {
    ...mapActions(DEAL_NAMESPACE, [
      'listComplianceFiles',
      'clearComplianceFiles',
      'listRequiredComplianceFor',
      'patchComplianceFile',
      'deleteComplianceFile',
      'patchDeal'
    ]),
    transformDate(value) {
      return value ? formatDT(value, { format: 'LL', tz: this.timezone, locale: this.locale }) : ''
    },
    create(complianceType) {
      let route = complianceType
        ? {
            name: 'addCompliance',
            params: { ...this.$route.params, fileId: 'new', complianceType }
          }
        : {
            name: 'addCompliance',
            params: { ...this.$route.params, fileId: 'new' }
          }
      this.$router.push(route)
    },
    edit(item) {
      this.$router.push({
        name: 'addCompliance',
        params: { ...this.$route.params, fileId: item.id }
      })
    },
    validate(item) {
      this.loading = true
      this.patchComplianceFile({ fileId: item.id, dealId: this.boardItem.entity.id, data: { valid: true } }).then(
        () => {
          this.loading = false
        }
      )
    },
    destroy(item) {
      confirmDeleteAction({}).then((res) => {
        if (res) {
          this.loading = true
          this.deleteComplianceFile({ fileId: item.id, dealId: this.boardItem.entity.id }).then(() => {
            this.loading = false
          })
        }
      })
    },
    fetchData() {
      this.listComplianceFiles({ dealId: this.boardItem.entity.id, pager: this.pager })
    }
  },
  created() {
    Promise.all([this.fetchData(), this.listRequiredComplianceFor(this.boardItem.entity.id)]).finally(() => {
      this.loading = false
    })
  },
  destroyed() {
    this.clearComplianceFiles()
  }
}
</script>
