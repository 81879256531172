<template>
  <div v-if="useList">
    <GroupApplicant
      v-for="app in dealClients"
      :deal-client="app"
      :key="`client-${app.client_id}-content-widget`"
      :read-only="readOnly"
    />
  </div>
  <v-tabs v-else>
    <v-toolbar dense color="transparent">
      <v-tabs v-model="activeTab">
        <v-tab ripple v-for="app in dealClients" :key="`client-${app.client_id}-tab`">
          {{ app.client.display }}
        </v-tab>
      </v-tabs>
      <v-spacer />
      <AddClient :deal-id="dealId" v-if="canEdit" />
    </v-toolbar>
    <v-tabs-items v-model="activeTab">
      <v-tab-item v-for="app in dealClients" :key="`client-${app.client_id}-content`" class="mt-4">
        <GroupApplicant :deal-client="app" :key="`client-${app.client_id}-content-widget`" :read-only="readOnly" />
      </v-tab-item>
    </v-tabs-items>
  </v-tabs>
</template>

<script>
import GroupApplicant from './GroupApplicant'
import ApplicantListMixin from './ApplicantListMixin'
import AddClient from './AddClient'

export default {
  name: 'GroupApplicants',
  mixins: [ApplicantListMixin],
  components: {
    GroupApplicant,
    AddClient
  },
  props: {
    useList: { type: Boolean, default: false }
  }
}
</script>
