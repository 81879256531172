<template>
  <VWidget content-class="pa-0">
    <template v-slot:title>{{ $t('labels.mainClient') }}</template>
    <template v-slot:content>
      <v-list class="pa-0 ma-0">
        <v-list-item>
          <v-list-item-content>
            <v-list-item-subtitle>{{ $t('labels.status') }}</v-list-item-subtitle>
            <v-list-item-title v-if="isMainClient">{{ $t('labels.yes') }}</v-list-item-title>
            <v-list-item-title v-if="!isMainClient">{{ $t('labels.no') }}</v-list-item-title>
          </v-list-item-content>
          <v-list-item-action v-if="!readOnly">
            <v-icon small color="success" v-if="isMainClient">{{ $vuetify.icons.values.check }}</v-icon>
            <v-btn v-else small depressed @click="setMainClient" :loading="loading"> {{ $t('setMainClient') }}</v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </template>
  </VWidget>
</template>

<script>
import { mapActions } from 'vuex'
import { VWidget } from '@argon/app/components'
import { NAMESPACE } from '../../store'

export default {
  name: 'VMainClient',
  components: { VWidget },
  props: {
    dealClient: { type: Object, required: true },
    readOnly: { type: Boolean, default: false }
  },
  data: () => ({
    loading: false
  }),
  computed: {
    isMainClient: {
      get() {
        return this.dealClient.is_main_provider_client
      },
      set(v) {
        this.setMainClient(v)
      }
    }
  },
  methods: {
    ...mapActions(NAMESPACE, ['patchDealClient']),
    setMainClient() {
      this.loading = true
      let payload = {
        dealId: this.dealClient.deal_id,
        clientId: this.dealClient.client_id,
        data: { is_main_provider_client: true }
      }
      this.patchDealClient(payload).then(() => {
        this.loading = false
      })
    }
  }
}
</script>

<style>
.v-toolbar .v-form.decision .v-input {
  margin-top: 16px !important;
}
</style>

<i18n>
{
  "en": {
    "setMainClient": "Set as main client"
  },
  "fr": {
    "setMainClient": "Définir comme client principal"
  }
}
</i18n>
