<template>
  <v-autocomplete
    v-model="localValue"
    :items="localCompliance"
    item-text="name"
    item-value="value"
    hide-no-data
    :label="$t('labels.type')"
    required
    :error-messages="errors"
    :menu-props="{ maxWidth: '316px', closeOnContentClick: true }"
  />
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { CustomFieldMixin } from '@argon/app/mixins'
import { NAMESPACE } from '../../store'

export default {
  name: 'ComplianceTypeField',
  mixins: [CustomFieldMixin],
  props: {
    provided: Array
  },
  methods: {
    ...mapActions(NAMESPACE, ['listComplianceTypes'])
  },
  computed: {
    ...mapState(NAMESPACE, ['compliance']),
    localCompliance() {
      return this.provided || this.compliance
    }
  },
  created() {
    let getCompliance = !this.provided && this.compliance.length === 0
    if (getCompliance) {
      this.listComplianceTypes().catch(() => {})
    }
  }
}
</script>

<style lang="scss" scoped>
>>> .v-list__tile {
  height: 72px !important;
  align-items: top !important;
}

.v-list__tile__sub-title {
  white-space: normal !important;
}
</style>
