<template>
  <v-row>
    <v-col d-flex cols="12" md="6">
      <v-row>
        <v-col cols="12"><VDealParticipants :deal="deal" :key="`participants-${deal.refid}`" /></v-col>
      </v-row>
    </v-col>
    <v-col d-flex cols="12" md="6">
      <v-row>
        <v-col cols="12"><v-deal-clients-minimal :deal-id="deal.id" :key="`clients-${deal.refid}`" /></v-col>
      </v-row>
      <v-row>
        <v-col cols="12"><v-deal-progress :deal="deal" :key="`progress-${deal.refid}`" /></v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import VDealParticipants from './VDealParticipants'
import VDealProgress from './VDealProgress'
import VDealClientsMinimal from './VDealClientsMinimal'

export default {
  name: 'HomeDealOverview',
  components: { VDealParticipants, VDealProgress, VDealClientsMinimal },
  props: {
    deal: { type: Object, required: true }
  }
}
</script>

<style></style>
