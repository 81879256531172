<template>
  <v-tooltip top transition="fade-transition" v-if="tooltip">
    <template v-slot:activator="{ on }">
      <v-icon small :color="iconColor" v-on="on">{{ icon }}</v-icon>
    </template>
    <span>{{ $t(`labels.${complianceState}`) }}</span>
  </v-tooltip>
  <v-icon small :color="iconColor" v-else>{{ icon }}</v-icon>
</template>

<script>
export default {
  name: 'ComplianceIcon',
  props: {
    deal: { type: Object, required: true },
    small: { type: Boolean, default: false },
    tooltip: { type: Boolean, default: false }
  },
  computed: {
    complianceState() {
      return this.deal.compliance
    },
    iconColor() {
      switch (this.deal.compliance) {
        case 'valid':
        case 'overridden':
          return 'success'
        case 'pending':
          return 'warning'
        default:
          return 'error'
      }
    },
    icon() {
      switch (this.deal.compliance) {
        case 'valid':
        case 'overridden':
          return this.$vuetify.icons.values.check
        case 'pending':
          return this.$vuetify.icons.values.warning
        default:
          return this.$vuetify.icons.values.error
      }
    }
  }
}
</script>

<style></style>
