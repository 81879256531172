<template>
  <div>
    <v-row>
      <v-col cols="12">
        <div class="text-h5">{{ $t('labels.deal') }}</div>
      </v-col>
      <v-col cols="12" md="6">
        <v-row>
          <v-col cols="12">
            <VAdvisor :advisor="deal.advisor" :key="`advisor-${deal.refid}`" read-only />
          </v-col>
          <v-col cols="12" v-if="$deal.isAllowed('atLeastManager')">
            <VCommissioning :deal="deal" :key="`commissioning-${deal.refid}`" read-only />
          </v-col>
          <v-col cols="12">
            <VBroker :deal="deal" :key="`broker-${deal.refid}`" read-only />
          </v-col>
          <v-col cols="12" v-if="$deal.isAllowed('atLeastManager')">
            <VFirm :firm="deal.firm" :key="`firm-${deal.refid}`" read-only />
          </v-col>
          <v-col cols="12">
            <VContractNumber :deal="deal" :key="`contract-${deal.refid}`" read-only />
          </v-col>
          <v-col cols="12">
            <VIndividualDealState :deal="deal" :key="`state-${deal.refid}`" read-only />
          </v-col>
          <v-col cols="12" v-if="parentGroupDeal">
            <VDealLeadInfo :deal="parentGroupDeal" :key="`info-${parentGroupDeal.refid}`" />
          </v-col>
          <v-col cols="12" v-if="$deal.isAllowed('atLeastAdvisor')">
            <VCompliance :deal="deal" />
          </v-col>
          <v-col cols="12">
            <VRelated :deal="deal" :key="`related-${deal.refid}`" />
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="6">
        <v-row>
          <v-col cols="12">
            <VDealProperties :deal="deal" :key="`properties-${deal.refid}`" />
          </v-col>
          <v-col cols="12" v-if="$deal.isAllowed('atLeastAdvisor')">
            <VDealBlocker :deal="deal" :key="`blocker-${deal.refid}`" read-only />
          </v-col>
          <v-col cols="12" v-if="parentGroupDeal">
            <VDealLoan :deal="parentGroupDeal" :key="`loan-${parentGroupDeal.refid}`" read-only />
          </v-col>
          <v-col cols="12" v-if="parentGroupDeal">
            <VDealDecision :deal="parentGroupDeal" :key="`decision-${parentGroupDeal.refid}`" />
          </v-col>
          <v-col cols="12" v-if="parentGroupDeal">
            <VSubjectProperty :subject="parentGroupDeal.property" :key="`property-${parentGroupDeal.refid}`" />
          </v-col>
          <v-col cols="12" v-if="parentGroupDeal">
            <VMortgage :mortgage="parentGroupDeal.mortgage" :key="`mortgage-${parentGroupDeal.refid}`" />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <IndividualApplicants :deal-id="deal.id" :key="`applicants-${deal.refid}`" read-only use-list />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import VDealParticipants from './VDealParticipants'
import VIndividualDealState from './VDealParticipants'
import VDealProgress from './VDealProgress'
import VCompliance from './VCompliance'
import VDealLeadInfo from '../general/VDealLeadInfo'
import VDealDecision from '../general/VDealDecision'
import VDealLoan from '../general/VDealLoan'
import VDealProperties from '../general/VDealProperties'
import VMortgage from '../general/VMortgage'
import VDealBlocker from '../general/VDealBlocker'
import VBroker from '../general/VBroker'
import VFirm from '../general/VFirm'
import VAdvisor from '../general/VAdvisor'
import VRelated from '../general/VRelated'
import VSubjectProperty from '../general/VSubjectProperty'
import VContractNumber from '../general/VContractNumber'
import VCommissioning from '../general/VCommissioning'
import IndividualApplicants from '../applicants/IndividualApplicants'
import dealPerimeter from '../../perimeters'
import { NAMESPACE } from '../../store'

export default {
  name: 'IndividualDealOverview',
  components: {
    VDealParticipants,
    VDealProgress,
    VCompliance,
    IndividualApplicants,
    VIndividualDealState,
    VDealLeadInfo,
    VDealLoan,
    VDealDecision,
    VMortgage,
    VSubjectProperty,
    VDealBlocker,
    VDealProperties,
    VAdvisor,
    VFirm,
    VBroker,
    VContractNumber,
    VCommissioning,
    VRelated
  },
  perimeters: [dealPerimeter],
  props: {
    deal: { type: Object, required: true }
  },
  data: () => ({
    parentGroupDeal: null
  }),
  methods: {
    ...mapActions(NAMESPACE, ['getDeal'])
  },
  created() {
    if (this.deal.parent_id) {
      this.getDeal({ dealId: this.deal.parent_id }).then((parentDeal) => {
        if (parentDeal.is_group) {
          this.parentGroupDeal = parentDeal
        }
      })
    }
  }
}
</script>

<style></style>
