var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('VWidget',{attrs:{"content-class":"pa-0"},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(_vm._s(_vm.$t('labels.partnerFiles')))]},proxy:true},{key:"header-actions",fn:function(){return [_c('v-btn',{attrs:{"text":"","loading":_vm.uploadingFortus},on:{"click":_vm.requestFortus}},[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v(_vm._s(_vm.$vuetify.icons.values.upload))]),_vm._v(" "+_vm._s(_vm.$t('sendToFortus'))+" ")],1),_c('v-btn',{attrs:{"text":"","loading":_vm.uploadingCRM},on:{"click":_vm.requestCRM}},[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v(_vm._s(_vm.$vuetify.icons.values.upload))]),_vm._v(" "+_vm._s(_vm.$t('sendToCRM'))+" ")],1)]},proxy:true},{key:"content",fn:function(){return [_c('FormErrors',{attrs:{"show":_vm.showResourceErrors,"errors":_vm.resourceErrors}}),_c('v-data-table',{attrs:{"hide-default-footer":"","hide-default-header":"","headers":_vm.headers,"items":_vm.partnerFiles,"server-items-length":_vm.partnerFiles.length,"loading":_vm.loading},scopedSlots:_vm._u([{key:"item.status_code",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.is_response ? item.meta.status_code : '-')+" ")]}},{key:"item.tracking_number",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.is_response ? item.meta.tracking_number : '-')+" ")]}},{key:"item.infrastructure",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.infrastructure.toUpperCase())+" ")]}},{key:"item.file_type",fn:function(ref){
var item = ref.item;
return [(item.is_response)?_c('v-tooltip',{attrs:{"top":"","transition":"fade-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"small":""}},on),[_vm._v(_vm._s(_vm.$vuetify.icons.values.ingress))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('labels.response')))])]):_c('v-tooltip',{attrs:{"top":"","transition":"fade-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"small":""}},on),[_vm._v(_vm._s(_vm.$vuetify.icons.values.egress))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('labels.request')))])])]}},{key:"item.success",fn:function(ref){
var item = ref.item;
return [(item.success)?_c('v-tooltip',{attrs:{"top":"","transition":"fade-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"success--text",attrs:{"small":""}},on),[_vm._v(_vm._s(_vm.$vuetify.icons.values.check))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('labels.success')))])]):_c('v-tooltip',{attrs:{"top":"","transition":"fade-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"error--text",attrs:{"small":""}},on),[_vm._v(_vm._s(_vm.$vuetify.icons.values.warning))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('labels.error'))+" ("+_vm._s(item.attempt_count)+")")])])]}},{key:"item.created_on",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-caption grey--text"},[_vm._v(_vm._s(_vm.transformDate(item.created_on)))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('DownloadBtn',{attrs:{"file":item.file}})]}}],null,true)})]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }