<template>
  <v-sheet color="error lighten-2 pa-1">
    <VWidget content-class="pa-0">
      <template v-slot:title>{{ $t('labels.errors') }}</template>
      <template v-slot:content>
        <v-list class="pa-0 ma-0">
          <v-list-item v-for="(error, idx) in deal.meta.errors" :key="`deal-error-${idx}`">
            <v-list-item-content v-if="typeof error === 'string'">
              <v-list-item-subtitle>{{ $t(`labels.error`) }}</v-list-item-subtitle>
              <v-list-item-title>{{ $t(`labels.${error}`) }}</v-list-item-title>
            </v-list-item-content>
            <v-list-item-content v-else>
              <v-list-item-subtitle>{{ $t(`labels.${error.label}`) }}</v-list-item-subtitle>
              <v-list-item-title>{{ error.message }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </template>
    </VWidget>
  </v-sheet>
</template>

<script>
import { VWidget } from '@argon/app/components'

export default {
  name: 'VErrors',
  components: { VWidget },
  props: {
    deal: { type: Object, required: true }
  }
}
</script>

<style></style>
