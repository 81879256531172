<template>
  <div>
    <v-row>
      <v-col cols="12">
        <div class="text-h5">{{ $t('labels.deal') }}</div>
      </v-col>
      <v-col cols="12" md="6">
        <v-row>
          <v-col cols="12">
            <VAdvisor :advisor="deal.advisor" :key="`advisor-${deal.refid}`" read-only />
          </v-col>
          <v-col cols="12">
            <VBroker :deal="deal" :key="`broker-${deal.refid}`" read-only />
          </v-col>
          <v-col cols="12" v-if="$deal.isAllowed('atLeastManager')">
            <VFirm :firm="deal.firm" :key="`firm-${deal.refid}`" read-only />
          </v-col>
          <v-col cols="12" v-if="$deal.isAllowed('atLeastAdvisor')">
            <VContract :deal-id="deal.id" :key="`contract-${deal.refid}`" read-only />
          </v-col>
          <v-col x12>
            <VDealProperties :deal="deal" :key="`properties-${deal.refid}`" read-only />
          </v-col>
          <v-col cols="12">
            <VGroupDealState :deal="deal" :key="`state-${deal.refid}`" read-only />
          </v-col>
          <v-col cols="12" v-if="$deal.isAllowed('atLeastAdvisor')">
            <VDealBlocker :deal="deal" :key="`blocker-${deal.refid}`" read-only />
          </v-col>
          <v-col cols="12">
            <VDealLeadInfo :deal="deal" :key="`info-${deal.refid}`" read-only />
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="6">
        <v-row>
          <v-col cols="12" v-if="$deal.isAllowed('atLeastAdvisor')">
            <VCompliance :deal="deal" :key="`compliance-${deal.refid}`" />
          </v-col>
          <v-col cols="12">
            <VRelated :deal="deal" :key="`related-${deal.refid}`" />
          </v-col>
          <v-col cols="12">
            <VDealLoan :deal="deal" :key="`loan-${deal.refid}`" read-only />
          </v-col>
          <v-col cols="12">
            <VDealDecision :deal="deal" :key="`decision-${deal.refid}`" read-only />
          </v-col>
          <v-col cols="12">
            <VSubjectProperty :subject="deal.property" :key="`property-${deal.refid}`" read-only />
          </v-col>
          <v-col cols="12">
            <VMortgage :mortgage="deal.mortgage" :key="`mortgage-${deal.refid}`" read-only />
          </v-col>
          <v-col cols="12" v-if="hasErrors">
            <VErrors :deal="deal" :key="`errors-${deal.refid}`" />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <GroupApplicants :deal-id="deal.id" :key="`GroupApplicants-${deal.refid}`" read-only use-list />
  </div>
</template>

<script>
import VDealParticipants from './VDealParticipants'
import VCompliance from './VCompliance'
import VErrors from './VErrors'
import dealPerimeter from '../../perimeters'
import VDealLeadInfo from '../general/VDealLeadInfo'
import VDealDecision from '../general/VDealDecision'
import VDealLoan from '../general/VDealLoan'
import VDealProperties from '../general/VDealProperties'
import VMortgage from '../general/VMortgage'
import VGroupDealState from '../general/VGroupDealState'
import VDealBlocker from '../general/VDealBlocker'
import VBroker from '../general/VBroker'
import VFirm from '../general/VFirm'
import VAdvisor from '../general/VAdvisor'
import VRelated from '../general/VRelated'
import VSubjectProperty from '../general/VSubjectProperty'
import GroupApplicants from '../applicants/GroupApplicants'
import { VPoliciesSummary, VContract } from '@/insurance/components'

export default {
  name: 'GroupDealOverview',
  perimeters: [dealPerimeter],
  components: {
    VDealParticipants,
    VCompliance,
    VPoliciesSummary,
    VErrors,
    VDealLeadInfo,
    VDealLoan,
    VDealDecision,
    VMortgage,
    VSubjectProperty,
    VGroupDealState,
    VDealBlocker,
    VDealProperties,
    VContract,
    VAdvisor,
    VFirm,
    VBroker,
    VRelated,
    GroupApplicants
  },
  props: {
    deal: { type: Object, required: true }
  },
  computed: {
    hasErrors() {
      return !!this.deal.meta.errors && this.deal.meta.errors.length > 0
    }
  }
}
</script>

<style></style>
