import { AddonMixin } from '@argon/evo/addons/mixins'


export default {
  name: 'DealAddonMixin',
  mixins: [AddonMixin],
  data: () => ({
    listParents: true,
  }),
  watch: {
    listParents: function (v) {
      this.fetchData()
    }
  },
  methods: {
    fetchData() {
      this.loading = true
      let payload = {
        refid: this.entity.refid,
        entityURL: this.entityURL,
        addon: this.addonName,
        pager: { ...this.pager, list_parents: this.listParents }
      }
      return this.listEntityAddons(payload).finally(() => {
        this.loading = false
        this.resetForm()
      })
    }
  }
}
