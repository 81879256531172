<template>
  <VWidget content-class="pa-0">
    <template v-slot:title>{{ $t('labels.partnerFiles') }}</template>
    <template v-slot:header-actions>
      <v-btn text @click="requestFortus" :loading="uploadingFortus">
        <v-icon left small>{{ $vuetify.icons.values.upload }}</v-icon>
        {{ $t('sendToFortus') }}
      </v-btn>
      <v-btn text @click="requestCRM" :loading="uploadingCRM">
        <v-icon left small>{{ $vuetify.icons.values.upload }}</v-icon>
        {{ $t('sendToCRM') }}
      </v-btn>
    </template>
    <template v-slot:content>
      <FormErrors :show="showResourceErrors" :errors="resourceErrors" />
      <v-data-table
        hide-default-footer
        hide-default-header
        :headers="headers"
        :items="partnerFiles"
        :server-items-length="partnerFiles.length"
        :loading="loading"
      >
        <template v-slot:[`item.status_code`]="{ item }">
          {{ item.is_response ? item.meta.status_code : '-' }}
        </template>
        <template v-slot:[`item.tracking_number`]="{ item }">
          {{ item.is_response ? item.meta.tracking_number : '-' }}
        </template>
        <template v-slot:[`item.infrastructure`]="{ item }">
          {{ item.infrastructure.toUpperCase() }}
        </template>
        <template v-slot:[`item.file_type`]="{ item }">
          <v-tooltip top transition="fade-transition" v-if="item.is_response">
            <template v-slot:activator="{ on }">
              <v-icon small v-on="on">{{ $vuetify.icons.values.ingress }}</v-icon>
            </template>
            <span>{{ $t('labels.response') }}</span>
          </v-tooltip>
          <v-tooltip top transition="fade-transition" v-else>
            <template v-slot:activator="{ on }">
              <v-icon small v-on="on">{{ $vuetify.icons.values.egress }}</v-icon>
            </template>
            <span>{{ $t('labels.request') }}</span>
          </v-tooltip>
        </template>

        <template v-slot:[`item.success`]="{ item }">
          <v-tooltip top transition="fade-transition" v-if="item.success">
            <template v-slot:activator="{ on }">
              <v-icon small class="success--text" v-on="on">{{ $vuetify.icons.values.check }}</v-icon>
            </template>
            <span>{{ $t('labels.success') }}</span>
          </v-tooltip>
          <v-tooltip top transition="fade-transition" v-else>
            <template v-slot:activator="{ on }">
              <v-icon small class="error--text" v-on="on">{{ $vuetify.icons.values.warning }}</v-icon>
            </template>
            <span>{{ $t('labels.error') }} ({{ item.attempt_count }})</span>
          </v-tooltip>
        </template>
        <template v-slot:[`item.created_on`]="{ item }">
          <span class="text-caption grey--text">{{ transformDate(item.created_on) }}</span>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <DownloadBtn :file="item.file" />
        </template>
      </v-data-table>
    </template>
  </VWidget>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex'
import { NAMESPACE as ME_NAMESPACE } from '@argon/iam/me/store'
import { NAMESPACE as DEAL_NAMESPACE } from '../../store'
import { formatDT } from '@argon/app/helpers'
import { BaseForm, FormErrors, VWidget, DownloadBtn } from '@argon/app/components'

import entityPerimeter from '@argon/evo/entities/perimeters'
import dealPerimeter from '../../perimeters'

export default {
  name: 'PartnerFiles',
  extends: BaseForm,
  perimeters: [entityPerimeter, dealPerimeter],
  components: { VWidget, FormErrors, DownloadBtn },
  props: {
    dealId: { type: [Number, String], required: true }
  },
  data: () => ({
    headers: [
      { text: '', value: 'file_type', sortable: false },
      { text: '', value: 'success', sortable: false },
      { text: '', value: 'infrastructure', sortable: false },
      { text: '', value: 'provider', sortable: false },
      { text: '', value: 'status_code', sortable: false },
      { text: '', value: 'tracking_number', sortable: false },
      { text: '', value: 'created_on', sortable: false },
      { text: '', sortable: false, value: 'actions', align: 'right' }
    ],
    loading: true,
    uploadingFortus: false,
    uploadingCRM: false
  }),
  computed: {
    ...mapGetters(ME_NAMESPACE, ['timezone', 'locale']),
    ...mapState(DEAL_NAMESPACE, ['partnerFiles'])
  },
  methods: {
    ...mapActions(DEAL_NAMESPACE, ['listPartnerFiles', 'clearPartnerFiles', 'requestPartnerUpload']),
    transformDate(value) {
      return value ? formatDT(value, { format: 'LL LTS', tz: this.timezone, locale: this.locale }) : ''
    },
    requestFortus() {
      this.uploadingFortus = true
      this.resetServerErrors()
      this.requestPartnerUpload({ dealId: this.dealId, data: { infrastructure: 'fortus' } })
        .catch(this.setServerErrors)
        .finally(() => {
          this.uploadingFortus = false
        })
    },
    requestCRM() {
      this.uploadingCRM = true
      this.resetServerErrors()
      this.requestPartnerUpload({ dealId: this.dealId, data: { infrastructure: 'crm' } })
        .catch(this.setServerErrors)
        .finally(() => {
          this.uploadingCRM = false
        })
    }
  },
  created() {
    Promise.all([this.listPartnerFiles(this.dealId)]).then(() => {
      this.loading = false
    })
  },
  destroyed() {
    this.clearPartnerFiles()
  }
}
</script>
<i18n>
{
  "en": {
    "sendToFortus": "Send to Fortus",
    "sendToCRM": "Send to CRM"
  },
  "fr": {
    "sendToFortus": "Envoyer à Fortus",
    "sendToCRM": "Envoyer au CRM"
  }
}
</i18n>
