import { mapActions, mapState } from 'vuex'
import { NAMESPACE } from '../../store'
import dealPerimeter from '../../perimeters'

export default {
  name: 'ApplicantListMixin',
  perimeters: [dealPerimeter],
  props: {
    dealId: { type: [Number, String], required: true },
    readOnly: { type: Boolean, default: false }
  },
  data: () => ({
    activeTab: 0
  }),
  computed: {
    ...mapState(NAMESPACE, ['dealClients']),
    canEdit() {
      return this.readOnly ? false : this.$deal.isAllowed('atLeastAdvisor')
    }
  },
  methods: {
    ...mapActions(NAMESPACE, ['listDealClients'])
  },
  created() {
    this.listDealClients(this.dealId)
  }
}
