<template>
  <v-autocomplete
    dense
    v-model="localValue"
    :items="localPresets"
    item-text="display"
    item-value="text"
    hide-no-data
    hide-details
    single-line
    clearable
    :placeholder="$t('labels.notes')"
    :menu-props="{ closeOnContentClick: true }"
    :loading="loading"
  />
</template>

<script>
import { groupBy } from 'lodash/collection'
import { mapActions, mapState } from 'vuex'
import { CustomFieldMixin } from '@argon/app/mixins'
import { NAMESPACE } from '../../store'

export default {
  name: 'NotePresetField',
  mixins: [CustomFieldMixin],
  data: () => ({
    loading: true
  }),
  methods: {
    ...mapActions(NAMESPACE, ['listNotePresets'])
  },
  computed: {
    ...mapState(NAMESPACE, ['notePresets']),
    localPresets() {
      let grouped = groupBy(this.notePresets, 'locale')
      let enPresets = grouped.en || []
      let frPresets = grouped.fr || []
      return [{ header: this.$t('labels.en') }, ...enPresets, { header: this.$t('labels.fr') }, ...frPresets]
    }
  },
  created() {
    this.listNotePresets().then(() => {
      this.loading = false
    })
  }
}
</script>

<style lang="scss" scoped>
>>> .v-list__tile {
  height: 72px !important;
  align-items: top !important;
}

.v-list__tile__sub-title {
  white-space: normal !important;
}
</style>
