<template>
  <VModalWidget :dismiss="dismiss">
    <template v-slot:title>{{ $t('title') }}</template>

    <template v-slot:content>
      <p class="subheading">{{ $t('question') }}</p>
    </template>

    <template slot="footer-actions">
      <v-btn depressed color="error" @click="dismiss()">{{ $t('labels.no') }}</v-btn>
      <v-btn depressed color="success" @click="dismiss(true)">{{ $t('labels.yes') }}</v-btn>
    </template>
  </VModalWidget>
</template>

<script>
import { VModalWidget } from '@argon/app/components'

export default {
  name: 'ConfirmDeleteFileDialog',
  components: {
    VModalWidget
  },
  methods: {
    dismiss(v = false) {
      this.$close(v)
    }
  }
}
</script>

<i18n>
{
  "en": {
    "question": "Are you sure you want to delete this file?",
    "title": "Delete File"
  },
  "fr": {
    "question": "Êtes-vous certain de vouloir supprimer ce fichier?",
    "title": "Supprimer le fichier"
  }
}
</i18n>
