<template>
  <VWidget content-class="pa-0">
    <template v-slot:title>{{ $t('title') }}</template>
    <template v-slot:content>
      <v-list class="pa-0 ma-0" v-for="(job, idx) in jobs" :key="`job-${idx}-${clientId}`">
        <v-divider v-if="idx !== 0" />
        <v-subheader class="subheading black--text">{{ job.title }}</v-subheader>
        <v-divider />
        <v-list-item>
          <v-list-item-content>
            <v-list-item-subtitle>{{ $t('monthsInIndustry') }}</v-list-item-subtitle>
            <v-list-item-title>{{ job.months_in_industry }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider />
        <v-list-item>
          <v-list-item-content>
            <v-list-item-subtitle>{{ $t('employer') }}</v-list-item-subtitle>
            <v-list-item-title>{{ job.contact.name }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider />
        <v-list-item>
          <v-list-item-content>
            <v-list-item-subtitle>{{ $t('sector') }}</v-list-item-subtitle>
            <v-list-item-title>{{ job.sector }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider />
        <v-list-item>
          <v-list-item-content>
            <v-list-item-subtitle>{{ $t('monthsInJob') }}</v-list-item-subtitle>
            <v-list-item-title>{{ job.months_of_service }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider />
        <v-list-item>
          <v-list-item-content>
            <v-list-item-subtitle>{{ $t('fulltime') }}</v-list-item-subtitle>
            <v-list-item-title>{{ job.fulltime }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider />
        <template>
          <v-list-item v-if="job.income">
            <v-list-item-content>
              <v-list-item-subtitle>{{ $t('payPeriod') }}</v-list-item-subtitle>
              <v-list-item-title>{{ job.income.period }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider />
          <v-list-item>
            <v-list-item-content>
              <v-list-item-subtitle>{{ $t('payType') }}</v-list-item-subtitle>
              <v-list-item-title>{{ job.income.name }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider />
          <v-list-item>
            <v-list-item-content>
              <v-list-item-subtitle>{{ $t('income') }}</v-list-item-subtitle>
              <v-list-item-title>{{ toCurrency(job.income.full_amount) }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
    </template>
  </VWidget>
</template>

<script>
import { mapGetters } from 'vuex'
import { NAMESPACE as ME_NAMESPACE } from '@argon/iam/me/store'
import { VWidget } from '@argon/app/components'
import { money } from '@/utils'

export default {
  name: 'Employment',
  components: { VWidget },
  props: {
    clientId: { type: [String, Number], required: true },
    jobs: { type: Array, default: () => [] }
  },
  computed: {
    ...mapGetters(ME_NAMESPACE, ['locale'])
  },
  methods: {
    toCurrency(v) {
      return money(v, this.locale)
    }
  }
}
</script>

<style scoped lang="scss">
.subheading {
  background-color: #fafafa;
  font-size: $font-size-root;
}
</style>

<i18n>
{
  "en": {
    "employer": "@:labels.employer",
    "fulltime": "@:labels.fulltime",
    "income": "@:labels.income",
    "monthsInIndustry": "@:labels.monthsInIndustry",
    "monthsInJob": "@:labels.monthsInJob",
    "payPeriod": "@:labels.payPeriod",
    "payType": "@:labels.payType",
    "sector": "@:labels.sector",
    "title": "@:labels.employments"
  },
  "fr": {
    "employer": "@:labels.employer",
    "fulltime": "@:labels.fulltime",
    "income": "@:labels.income",
    "monthsInIndustry": "@:labels.monthsInIndustry",
    "monthsInJob": "@:labels.monthsInJob",
    "payPeriod": "@:labels.payPeriod",
    "payType": "@:labels.payType",
    "sector": "@:labels.sector",
    "title": "@:labels.employments"
  }
}
</i18n>
