<template>
  <VWidget content-class="pa-0">
    <template v-slot:title>{{ $t('labels.files') }}</template>
    <template v-slot:header-actions>
      <v-switch hide-details v-model="listParents" :label="$t('labels.listParents')" />
    </template>
    <template v-slot:content>
      <v-card flat class="elevation-0">
        <v-card-text>
          <FormErrors :show="showResourceErrors" :errors="resourceErrors" />
          <v-row>
            <v-col cols="12">
              <v-toolbar dense flat>
                <v-switch
                  hide-details
                  color="success"
                  v-model="form.private"
                  class="shrink mr-2 mt-0"
                  :label="$t('labels.private')"
                  v-if="enablePrivate"
                />
                <v-spacer />
                <v-tooltip top transition="fade-transition">
                  <template v-slot:activator="{ on }">
                    <v-btn icon small class="py-0 px-0" @click="resetForm" v-on="on">
                      <v-icon small>{{ $vuetify.icons.values.reset }}</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('labels.reset') }}</span>
                </v-tooltip>
              </v-toolbar>
            </v-col>
            <v-col cols="3" v-if="useOwner">
              <UserField v-model="form.owner_id" :provided="members" :label="$t('labels.owner')" />
            </v-col>
            <v-col :cols="useOwner ? 9 : 12">
              <FileUploadField
                :label="$t('addDocument')"
                :icon="$vuetify.icons.values.attachment"
                @input="onFiles"
                prefix="addons"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text class="pb-2 px-0 pt-0">
          <v-alert v-model="alert" dismissible type="error" transition="scale-transition" class="subheading mx-0">
            {{ $t(alertMessage) }}
          </v-alert>
          <v-data-table
            hide-default-header
            :headers="headers"
            :items="matches"
            :loading="loading"
            :server-items-length="matchesCount"
            :page.sync="page"
            :sort-by.sync="sortBy"
            :items-per-page.sync="itemsPerPage"
            :footer-props="{ itemsPerPageOptions: itemsPerPageArray }"
          >
            <template v-slot:[`item.icon`]="{ item }">
              <v-icon small v-text="$vuetify.icons.values[item.file.extension] || $vuetify.icons.values.file" />
            </template>
            <template v-slot:[`item.text`]="{ item }">
              {{ item.file.filename }}
            </template>
            <template v-slot:[`item.owner`]="{ item }">
              {{ item.owner ? item.owner.display : null }}
            </template>
            <template v-slot:[`item.private`]="{ item }">
              <v-chip label small color="warning" text-color="warning darken-4" v-if="item.private">
                {{ $t('labels.private') }}
              </v-chip>
              &nbsp;&nbsp;
              <v-chip label small text-color="warning darken-4" v-if="item.parent">
                {{ $t('labels.parent') }}
              </v-chip>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <DownloadBtn :file="item.file" />
              <v-btn icon small class="py-0 px-0" @click="remove(item)" v-if="canEdit">
                <v-icon small>{{ $vuetify.icons.values.cancel }}</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </template>
  </VWidget>
</template>

<script>
import { VWidget, FormErrors, DownloadBtn, FileUploadField } from '@argon/app/components'
import { UserField } from '@argon/iam/components'
import DealAddonMixin from './DealAddonMixin'

const ADDON = 'documents'

export default {
  name: 'Documents',
  components: { VWidget, FormErrors, UserField, DownloadBtn, FileUploadField },
  mixins: [DealAddonMixin],
  validations: {
    form: {}
  },
  data() {
    return {
      form: {
        file_id: null,
        private: this.enablePrivate
      },
      filename: '',
      addonName: ADDON,
      uploading: false,
      headers: [
        { text: '', value: 'icon' },
        { text: '', value: 'text' },
        { text: '', value: 'owner' },
        { text: '', value: 'private', width: 30 },
        { text: '', value: 'actions', width: 30, align: 'right' }
      ]
    }
  },
  methods: {
    onFiles(files) {
      files.forEach((file) => {
        this.form.file_id = file.id
        this.add(this.form)
      })
    },
    resetForm() {
      this.form = { file_id: null, private: this.enablePrivate }
    },
    buildData() {
      return { text: this.form.text, private: this.enablePrivate, file_id: this.form.file_id }
    },
    canEditItem(instance) {
      return this.canEdit && instance.owner_id === this.userId
    }
  }
}
</script>

<i18n>
{
  "en": {
    "addDocument": "Upload a document",
    "deleteError": "Error deleting document"
  },
  "fr": {
    "addDocument": "Téléversez un document",
    "deleteError": "Erreur de suppression du document"
  }
}
</i18n>
