<template>
  <VWidget content-class="pa-0">
    <template v-slot:title>{{ $t('title') }}</template>
    <template v-slot:content>
      <v-list subheader class="pa-0 ma-0" v-for="(asset, idx) in assets" :key="`asset-${idx}-${clientId}`">
        <v-divider v-if="idx !== 0" />
        <v-subheader class="subheading">{{ asset.description }}</v-subheader>
        <v-divider />
        <v-list-item>
          <v-list-item-content>
            <v-list-item-subtitle>{{ $t('type') }}</v-list-item-subtitle>
            <v-list-item-title>{{ asset.type }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider />
        <v-list-item>
          <v-list-item-content>
            <v-list-item-subtitle>{{ $t('amount') }}</v-list-item-subtitle>
            <v-list-item-title>{{ toCurrency(asset.value) }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </template>
  </VWidget>
</template>

<script>
import { mapGetters } from 'vuex'
import { NAMESPACE as ME_NAMESPACE } from '@argon/iam/me/store'
import { VWidget } from '@argon/app/components'
import { money } from '../../../utils'

export default {
  components: { VWidget },
  props: {
    clientId: { type: [String, Number], required: true },
    assets: { type: Array, default: () => [] }
  },
  computed: {
    ...mapGetters(ME_NAMESPACE, ['locale'])
  },
  methods: {
    toCurrency(v) {
      return money(v, this.locale)
    }
  }
}
</script>

<i18n>
{
  "en": {
    "amount": "@:labels.amount",
    "title": "@:labels.assets",
    "type": "@:labels.type"
  },
  "fr": {
    "amount": "@:labels.amount",
    "title": "@:labels.assets",
    "type": "@:labels.type"
  }
}
</i18n>
