<template>
  <VWidget content-class="pa-0">
    <template v-slot:title>{{ $t('labels.delete') }}</template>
    <template v-slot:content>
      <FormErrors :show="showResourceErrors" :errors="resourceErrors" />
      <v-list class="pa-0 ma-0">
        <v-list-item>
          <v-list-item-subtitle>{{ $t('deleteClient') }}</v-list-item-subtitle>
          <v-list-item-action>
            <v-btn small icon @click="onDelete">
              <v-icon>{{ $vuetify.icons.values.cancel }}</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </template>
  </VWidget>
</template>

<script>
import { mapActions } from 'vuex'
import { create } from 'vue-modal-dialogs'
import { VWidget, BaseForm, FormErrors } from '@argon/app/components'
import { NAMESPACE } from '../../store'
import ConfirmRemoveClientDialog from './ConfirmRemoveClientDialog'
const confirmDeleteAction = create(ConfirmRemoveClientDialog)

export default {
  name: 'VDeleteClient',
  extends: BaseForm,
  components: { VWidget, FormErrors },
  props: {
    dealId: { type: [Number, String], required: true },
    clientId: { type: [Number, String], required: true }
  },
  methods: {
    ...mapActions(NAMESPACE, ['deleteDealClient']),
    onDelete() {
      this.resetServerErrors()
      confirmDeleteAction().then((res) => {
        if (res) {
          this.deleteDealClient({ dealId: this.dealId, clientId: this.clientId }).catch(this.setServerErrors)
        }
      })
    }
  }
}
</script>

<i18n>
{
  "en": {
    "deleteClient": "Remove the client from this deal"
  },
  "fr": {
    "deleteClient": "Retirer le client de ce dossier"
  }
}
</i18n>
