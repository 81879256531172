<template>
  <VModalWidget :dismiss="dismiss">
    <template v-slot:title>{{ $t('title') }}</template>

    <template v-slot:content>
      <p class="subheading">{{ $t('question') }}</p>
      <v-alert type="warning" :value="true" class="subheading bordered">
        {{ $t('description') }}
      </v-alert>
    </template>

    <template slot="footer-actions">
      <v-btn depressed color="error" @click="dismiss()">{{ $t('no') }}</v-btn>
      <v-btn depressed color="success" @click="dismiss(true)">{{ $t('yes') }}</v-btn>
    </template>
  </VModalWidget>
</template>

<script>
import { VModalWidget } from '@argon/app/components'

export default {
  name: 'ConfirmRemoveClientDialog',
  components: {
    VModalWidget
  },
  methods: {
    dismiss(v = false) {
      this.$close(v)
    }
  }
}
</script>

<i18n>
{
  "en": {
    "description": "All related items will be irreversibly deleted.",
    "no": "@:labels.no",
    "question": "Are you sure you want to delete this client from this deal ?",
    "title": "Remove Client",
    "yes": "@:labels.yes"
  },
  "fr": {
    "description": "Tous les éléments associés seront irréversiblement supprimés.",
    "no": "@:labels.no",
    "question": "Êtes-vous certain de vouloir supprimer ce client de ce dossier ?",
    "title": "Retirer le Client",
    "yes": "@:labels.yes"
  }
}
</i18n>
