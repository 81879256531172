<template>
  <VWidget content-class="pa-0 ma-0">
    <template v-slot:title>{{ $t('labels.clients') }}</template>
    <template v-slot:content>
      <v-list class="pa-0 ma-0" v-for="(app, idx) in dealClients" :key="`client-${idx}`">
        <v-divider v-if="idx !== 0" />
        <v-subheader :key="`client-${app.client_id}-name`" class="subheading black--text">{{
          app.client.display
        }}</v-subheader>
        <template>
          <v-divider :key="`client-${app.client_id}-main-divider`" />
          <v-list-item :key="`client-${app.client_id}-main`">
            <v-list-item-content>
              <v-list-item-subtitle>{{ $t('labels.mainClient') }}</v-list-item-subtitle>
              <v-list-item-title>
                {{ app.is_main_provider_client ? $t('labels.yes') : $t('labels.no') }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
    </template>
  </VWidget>
</template>

<script>
import { VWidget } from '@argon/app/components'
import { ApplicantListMixin } from '../applicants'

export default {
  name: 'VDealClientsMinimal',
  mixins: [ApplicantListMixin],
  components: { VWidget }
}
</script>

<style scoped lang="scss">
.subheading {
  background-color: #fafafa;
  font-size: $font-size-root;
}
</style>
