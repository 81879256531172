var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('VWidget',{attrs:{"content-class":"pa-0"},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(_vm._s(_vm.$t('labels.files')))]},proxy:true},{key:"header-actions",fn:function(){return [_c('v-switch',{attrs:{"hide-details":"","label":_vm.$t('labels.listParents')},model:{value:(_vm.listParents),callback:function ($$v) {_vm.listParents=$$v},expression:"listParents"}})]},proxy:true},{key:"content",fn:function(){return [_c('v-card',{staticClass:"elevation-0",attrs:{"flat":""}},[_c('v-card-text',[_c('FormErrors',{attrs:{"show":_vm.showResourceErrors,"errors":_vm.resourceErrors}}),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-toolbar',{attrs:{"dense":"","flat":""}},[(_vm.enablePrivate)?_c('v-switch',{staticClass:"shrink mr-2 mt-0",attrs:{"hide-details":"","color":"success","label":_vm.$t('labels.private')},model:{value:(_vm.form.private),callback:function ($$v) {_vm.$set(_vm.form, "private", $$v)},expression:"form.private"}}):_vm._e(),_c('v-spacer'),_c('v-tooltip',{attrs:{"top":"","transition":"fade-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"py-0 px-0",attrs:{"icon":"","small":""},on:{"click":_vm.resetForm}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(_vm._s(_vm.$vuetify.icons.values.reset))])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('labels.reset')))])])],1)],1),(_vm.useOwner)?_c('v-col',{attrs:{"cols":"3"}},[_c('UserField',{attrs:{"provided":_vm.members,"label":_vm.$t('labels.owner')},model:{value:(_vm.form.owner_id),callback:function ($$v) {_vm.$set(_vm.form, "owner_id", $$v)},expression:"form.owner_id"}})],1):_vm._e(),_c('v-col',{attrs:{"cols":_vm.useOwner ? 9 : 12}},[_c('FileUploadField',{attrs:{"label":_vm.$t('addDocument'),"icon":_vm.$vuetify.icons.values.attachment,"prefix":"addons"},on:{"input":_vm.onFiles}})],1)],1)],1),_c('v-card-text',{staticClass:"pb-2 px-0 pt-0"},[_c('v-alert',{staticClass:"subheading mx-0",attrs:{"dismissible":"","type":"error","transition":"scale-transition"},model:{value:(_vm.alert),callback:function ($$v) {_vm.alert=$$v},expression:"alert"}},[_vm._v(" "+_vm._s(_vm.$t(_vm.alertMessage))+" ")]),_c('v-data-table',{attrs:{"hide-default-header":"","headers":_vm.headers,"items":_vm.matches,"loading":_vm.loading,"server-items-length":_vm.matchesCount,"page":_vm.page,"sort-by":_vm.sortBy,"items-per-page":_vm.itemsPerPage,"footer-props":{ itemsPerPageOptions: _vm.itemsPerPageArray }},on:{"update:page":function($event){_vm.page=$event},"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:itemsPerPage":function($event){_vm.itemsPerPage=$event},"update:items-per-page":function($event){_vm.itemsPerPage=$event}},scopedSlots:_vm._u([{key:"item.icon",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"small":""},domProps:{"textContent":_vm._s(_vm.$vuetify.icons.values[item.file.extension] || _vm.$vuetify.icons.values.file)}})]}},{key:"item.text",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.file.filename)+" ")]}},{key:"item.owner",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.owner ? item.owner.display : null)+" ")]}},{key:"item.private",fn:function(ref){
var item = ref.item;
return [(item.private)?_c('v-chip',{attrs:{"label":"","small":"","color":"warning","text-color":"warning darken-4"}},[_vm._v(" "+_vm._s(_vm.$t('labels.private'))+" ")]):_vm._e(),_vm._v("    "),(item.parent)?_c('v-chip',{attrs:{"label":"","small":"","text-color":"warning darken-4"}},[_vm._v(" "+_vm._s(_vm.$t('labels.parent'))+" ")]):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('DownloadBtn',{attrs:{"file":item.file}}),(_vm.canEdit)?_c('v-btn',{staticClass:"py-0 px-0",attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.remove(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(_vm._s(_vm.$vuetify.icons.values.cancel))])],1):_vm._e()]}}],null,true)})],1)],1)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }